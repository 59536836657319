.pillNavContainer {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(57, 53, 49, 0.3);
  border-radius: 999px;
  display: flex;
  z-index: 5000;
}

.navItem {
  padding: 0.4rem 0.9rem;
  border-radius: 999px;
  color: white;
  font-size: 0.95rem;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navText {
  display: inline-block;
}

.navItem:hover {
  color: #fdf9f3;
}

.highlightPill {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 999px;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.65, 0, 0.35, 1);
}

@media (max-width: 768px) {
  .pillNavContainer {
    bottom: 0.8rem;
  }

  .navItem {
    padding: 0.3rem 0.7rem;
    font-size: 0.85rem;
  }
}
