.navbarContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  position: sticky;
  background-color: #c4a4a4;
  filter: drop-shadow(0 10px 40px rgba(0, 0, 0, 0.5));
  z-index: 1000;
  /* Default border-radius for when the navbar is expanded */
  border-radius: 0;
  transition: border-radius 1.2s ease;  /* Smooth transition for border-radius */
}

.navbarContainer.shrunk {
  /* Apply rounded border when navbar shrinks */
  border-radius: 0px 0px 20px 20px;
}

.shrinkContainer {
  height: 24vh;
}

  
  .socialLinks {
    position: absolute;
    z-index: 70;
    padding-top: 15px;
    opacity: 0.75;
    display: flex;
    gap: 1rem;
    margin-left: 1rem;
  }
  
  .socialLinks a {
    transition: opacity 0.3s ease;
  }
  
  .socialLinks a:hover {
    opacity: 1;
  }
  
  .backgroundImage {
    top: 0;
    position: static;
    width: 100vw;
    opacity: 0.05;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 5px;
  }
  
  .iconWrapper.instagram {
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
  }
  
  .iconWrapper.facebook {
    background: linear-gradient(45deg, #1877f2 0%, #4facfe 100%);
  }

  .iconWrapper.email {
    background: linear-gradient(45deg, #4facfe 0%, #00f2fe 110%);
  }
  .iconWrapper:hover {
    transform: scale(1.2); /* Increase size */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(255, 255, 255, 0.7); /* Underglow effect */
  }
  