.legal-container {
  padding: 1rem;
  margin: 0 auto;
}

.legal-box {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: 0px;
  padding: 20px;
  text-align: center;
  align-items: center;
}

.faq-section,
.legal-section {
  margin-bottom: 3rem;
}

.faq-section h2,
.legal-section h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
}

.faq-item {
  margin-bottom: 1.5rem;
}

.faq-item h3 {
  margin-bottom: 0.5rem;
  color: #333;
}

.faq-item p {
  color: #555;
  line-height: 1.6;
}

.legal-section p,
.legal-section ul {
  color: #555;
  line-height: 1.6;
}

.legal-section ul {
  list-style-type: none;
  padding-left: 0;
}

.legal-section ul li {
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .legal-container {
    padding: 1rem;
  }
}
