html,
body {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fcdbd4;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
::-webkit-scrollbar {
  display: none;
}
body.no-scroll {
  overflow: hidden;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

:root {
  color-scheme: only light;
}
