.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: 50px;
  font-size: 0.9rem;
}

.footer a {
  color: #fdf9f3;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #ddd;
}
