/* === Profile Image === */
.simmi-profile-image {
  width: 30%;
  border-radius: 1rem;
  filter: drop-shadow(0 0px 2rem rgba(0, 0, 0, 0.6));
}

/* === Orientation Layout === */
.landingOreintation {
  align-items: center;
  text-align: center;
}

/* === Contact Section === */
.AboutSimmiContact {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  padding-top: 0;
  text-align: center;
}

/* Contact Buttons Layout */
.click-me-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin: 20px 0;
}

/* Common Button Style */
.click-me-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px;
  min-width: 220px;
  max-width: 240px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: #fdf9f3 !important;
  font-weight: bold;
  cursor: pointer;
}

/* Instagram Button */
a.contact-instagram {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  ) !important;
}

/* Email Button */
a.contact-email {
  background: linear-gradient(45deg, #4facfe 0%, #00f2fe 120%) !important;
}

/* Hover Effects */
.click-me-links a:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

/* Icon Styling */
.click-me-links svg {
  margin-top: 10px;
}

/* === Info Section === */
.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* === Landing Box === */
.landingBox {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: 10px;
  text-align: center;
  align-items: center;
}

/* === Paragraph Styling === */
.simmiParagraph {
  align-items: center;
  text-align: center;
}

.simmiParagraph p {
  font-size: large;
}

/* === Brand Icons Section === */
.brand-icons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}

.brand-icon {
  width: auto;
  height: 3rem;
}

/* === Responsive Layout === */
@media (max-width: 1099px) {
  .simmi-profile-image {
    width: 40% !important;
    margin: 1rem;
  }

  .simmiParagraph p {
    margin: 0 2rem 2rem 2rem !important;
  }

  .landingOreintation {
    margin: 1rem;
  }
}

@media (min-width: 1100px) {
  .landingBox {
    width: 90% !important;
  }

  .landingOreintation {
    display: flex;
    flex-direction: row;
  }

  .simmiParagraph {
    margin: 0.5rem 0 4rem 0;
  }

  .simmiParagraph p {
    padding: 0.5rem 4rem 0 4rem;
  }

  .simmi-profile-image {
    margin: 1.25rem 0 4rem 3rem;
  }
}

/* Apply styles to only the specified <br /> */
.styled-line{
  content: "";
  display: block;
  width: 90vw;  /* Set the width to 75% of the container */
  margin: 1rem auto;  /* Center the line and add some space above and below */
  border-bottom: 1px solid #333;  /* Create the line with a solid color */
}